<template>
  <nav class="tabs flex flex-col sm:flex-row pt-5">
    <button
      v-if="props.mode === '2D'"
      class="
        tab
        active
        text-gray-600
        py-2
        px-6
        block
        hover:text-blue-500
        focus:outline-none
        border-b-2 border-blue-500
        text-blue-500
        font-medium
      "
    >
      2D
    </button>
    <button
      button
      v-if="props.mode === '3D'"
      class="
        tab
        active
        text-gray-600
        py-2
        px-6
        block
        hover:text-blue-500
        focus:outline-none
        border-b-2 border-blue-500
        text-blue-500
        font-medium
      "
    >
      3D
    </button>
  </nav>

  <div class="p-5">
    <div class="flex justify-between">
      <h1 class="font-bold text-secondary">Listado de planos</h1>
      <button
        class="
          text-white
          border
          text-sm
          font-medium
          bg-primary
          border-primary
          py-2
          px-5
        "
        @click="onOpenDrawerPlan({ action: 'create' })"
      >
        Agregar plano
      </button>
    </div>
    <div class="flex py-5">
      <h4 class="my-auto mr-5">Especialidad</h4>
      <select
        v-model="filterSpeciality"
        class="
          relative
          py-3
          px-2
          bg-white
          rounded-sm
          border
          outline-none
          text-gray-700
          placeholder-gray-400
        "
        placeholder="Seleccione"
      >
        <option value="">Todos</option>
        <option
          v-for="speciality in getPlaneSpecialities"
          :key="speciality"
          :value="speciality.key"
        >
          {{ speciality.name }}
        </option>
      </select>
    </div>

    <div class="grid relative w-full lg:px-5">
      <div class="overflow-x-scroll">
        <table class="w-full">
          <thead>
            <tr>
              <th class="py-2 text-sm text-gray-500 text-left">Especialidad</th>
              <th
                class="px-4 py-2 text-sm text-gray-500 text-center lg:text-left"
              >
                Nombre
              </th>
              <th class="py-2 text-sm text-gray-500"></th>
              <th class="py-2 text-sm text-gray-500"></th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-300">
            <tr
              v-for="plan in getPlansFiltered"
              :key="plan.key"
              class="whitespace-nowrap"
            >
              <td
                class="
                  py-4
                  text-sm text-secondary
                  font-light
                  text-gray-500 text-left
                "
              >
                {{ plan.speciality.name }}
              </td>
              <td class="px-4 py-4">
                <div
                  class="
                    text-sm text-center
                    lg:text-left
                    text-secondary
                    font-light
                    text-gray-900
                  "
                >
                  {{ plan.name }}
                </div>
              </td>
              <td class="py-4">
                <a
                  v-if="plan.progress === 'complete'"
                  class="
                    text-primary
                    font-medium
                    cursor-pointer
                    text-right
                    w-full
                    mr-10
                  "
                  @click="onOpenModalPlaneViewer(plan)"
                >
                  Ver plano
                </a>
                <span v-else class="text-sm">En progreso</span>
              </td>
              <td class="py-4">
                <div class="flex md:justify-end">
                  <a
                    class="
                      text-primary
                      font-medium
                      cursor-pointer
                      text-right
                      hover:bg-blue-50
                      px-2
                    "
                    @click="onOpenDrawerPlan({ action: 'update', plan: plan })"
                  >
                    Editar
                  </a>
                  <button
                    class="ml-4 hover:bg-red-50 px-2"
                    @click="onOpenModalDeletePlan(plan)"
                  >
                    <!-- <span class="text-red-500 font-medium">Eliminar</span> -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="black"
                    >
                      <path
                        d="M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56976C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56976V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z"
                        fill="#E00F57"
                      />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <PlaneViewer
    v-if="modalPlaneViewer"
    :open="modalPlaneViewer"
    @update:open="modalPlaneViewer = $event"
    :plan="planSelected"
  ></PlaneViewer>

  <EndDrawer :open="drawerPlan" @update:open="drawerPlan = $event">
    <PlanForm
      v-if="drawerPlan"
      :action="planFormAction"
      :plan-selected="planSelected"
      @onComplete="drawerPlan = false"
    />
  </EndDrawer>

  <Modal :open="modalDeletePlan" @update:open="modalDeletePlan = $event">
    <template #body>
      <span v-if="planSelected" class="font-medium">
        ¿Desea eliminar el plano "{{ planSelected.name }}"?
      </span>
      <br />
    </template>
    <template #buttonDelete>
      <button
        type="button"
        class="
          w-full
          inline-flex
          justify-center
          rounded-md
          border border-transparent
          shadow-sm
          px-4
          py-2
          bg-red-600
          text-base
          font-medium
          text-white
          hover:bg-red-700
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
          sm:ml-3 sm:w-auto sm:text-sm
        "
        :disabled="loadingDelete"
        @click="onDeletePlan"
      >
        <LoadingButtonIcon v-if="loadingDelete" />
        Eliminar
      </button>
    </template>
  </Modal>
</template>

<script setup>
import { defineProps, ref, computed, watch, onUnmounted } from "vue";
import { useStore } from "vuex";
import PlanForm from "./components/PlanForm";
import PlaneViewer from "./components/PlaneViewer";

const props = defineProps({
  mode: {
    type: String,
    required: true,
  },
});

const store = useStore();

const filterSpeciality = ref("");
const planSelected = ref(null);
const planFormAction = ref("create");
const drawerPlan = ref(false);
const loading = ref(false);
const modalPlaneViewer = ref(false);
const modalDeletePlan = ref(false);
const loadingDelete = ref(false);

const getPlans = computed(() => store.getters.getPlans);
const getPlaneSpecialities = computed(() => store.getters.getPlaneSpecialities);
const getPlansFiltered = computed(() =>
  getPlans.value?.filter((p) => p.type === `Plane${props.mode}`)
);

watch(
  () => filterSpeciality.value,
  (currentSpeciality) => loadPlans(currentSpeciality)
);

const loadPlans = async (specialityKey = "") => {
  try {
    loading.value = true;

    await store.dispatch("getAllPlans", {
      specialityKey,
      type: `Plane${props.mode}`,
    });
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};

const init = async () => {
  await loadPlans();

  if (!getPlaneSpecialities.value)
    await store.dispatch("getAllSpecialities", { type: "Plane" });
};

init();

const onOpenDrawerPlan = ({ action, plan = null }) => {
  drawerPlan.value = true;
  planSelected.value = { ...plan, type: props.mode };
  planFormAction.value = action;
};

const onOpenModalPlaneViewer = (plan) => {
  planSelected.value = { ...plan };
  modalPlaneViewer.value = true;
};

const onOpenModalDeletePlan = (plan) => {
  planSelected.value = { ...plan };
  modalDeletePlan.value = true;
};

const onDeletePlan = async () => {
  try {
    loadingDelete.value = true;

    await store.dispatch("deletePlan", {
      planKey: planSelected.value.key,
    });

    modalDeletePlan.value = false;
    planSelected.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    loadingDelete.value = false;
  }
};

onUnmounted(() => {
  if (filterSpeciality.value) store.dispatch("resetPlan");
});
</script>
