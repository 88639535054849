<template>
  <Form
    @submit="onSavePlane"
    :validation-schema="{
      name: 'required',
      specialityKey: 'required',
    }"
  >
    <div class="pt-8">
      <h4 class="mb-6 text-secondary">
        {{ action === "create" ? "Agregar plano" : "Editar plano" }}
        {{ props.planSelected.type }}
      </h4>
    </div>
    <div class="pb-4">
      <label class="text-dark font-semibold text-sm">Nombre</label>
      <Field v-model="name" name="name" v-slot="{ field }">
        <input
          v-bind="field"
          type="text"
          class="
            relative
            py-2
            px-2
            w-full
            bg-white
            rounded
            border
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none focus:shadow-outline
            mt-2
          "
          placeholder="Ingrese nombre del plano"
        />
      </Field>
      <div>
        <ErrorMessage name="name" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm text-left">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <div class="mb-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-sm">Especialidad</label>
        <Field v-model="specialityKey" name="specialityKey" v-slot="{ field }">
          <select
            v-bind="field"
            class="
              relative
              py-3
              px-2
              bg-white
              rounded-sm
              border
              outline-none
              text-gray-700
              placeholder-gray-400
              w-full
            "
            placeholder="Seleccione"
          >
            <option
              v-for="speciality in getPlaneSpecialities"
              :key="speciality"
              :value="speciality.key"
            >
              {{ speciality.name }}
            </option>
          </select>
        </Field>
      </div>
      <ErrorMessage name="specialityKey" v-slot="{ message }">
        <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
      </ErrorMessage>
    </div>
    <div class="mb-4 mt-4">
      <div class="relative w-full flex flex-wrap items-stretch">
        <label class="text-sm">Plano</label>
        <input
          type="file"
          class="
            relative
            py-3
            px-2
            w-full
            bg-white
            rounded
            shadow
            outline-none
            text-gray-700
            placeholder-gray-400
            focus:outline-none focus:shadow-outline
          "
          placeholder="Seleccione"
          required
          @change="previewFiles"
        />
      </div>
      <div>
        <ErrorMessage name="file" v-slot="{ message }">
          <p class="text-red-500 pt-1 text-sm">{{ message }}</p>
        </ErrorMessage>
      </div>
    </div>
    <Alert
      v-if="responseMessage"
      :type="responseType"
      :text="responseMessage"
    ></Alert>
    <div>
      <button
        :disabled="loadingSave"
        class="rounded bg-primary py-2 px-6 text-white flex"
        type="submit"
      >
        <LoadingButtonIcon v-if="loadingSave" />
        Guardar
      </button>
    </div>
  </Form>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed } from "vue";
import { useStore } from "vuex";
import { PDFDocument } from "pdf-lib";
import { uploadFileToStorage } from "@/services/storage";

const props = defineProps({
  action: {
    type: String,
    default: "create",
  },
  planSelected: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(["onComplete"]);

const store = useStore();

let name = ref("");
let specialityKey = ref("");
let fileToUpload = ref(null);
let responseType = ref(null);
let responseMessage = ref(null);
let loadingSave = ref(false);

const getWork = computed(() => store.getters.getWork);
const getPlaneSpecialities = computed(() => store.getters.getPlaneSpecialities);

const init = async () => {
  if (!getPlaneSpecialities.value)
    await store.dispatch("getAllSpecialities", { type: "Plane" });

  if (props.action === "update") {
    name.value = props.planSelected.name;
    specialityKey.value = props.planSelected.speciality?.key;
  }
};

init();

const onSavePlane = async (values) => {
  try {
    loadingSave.value = true;
    responseType.value = null;
    responseMessage.value = null;

    if (props.action === "create" && !fileToUpload.value)
      throw new Error("Es necesario subir el archivo del plano");

    const plan = {
      name: values.name,
      specialityKey: values.specialityKey,
      type: `Plane${props.planSelected.type}`,
    };

    let isToContinue = true;

    if (fileToUpload.value.type === "application/pdf") {
      const arrayBuffer = await fileToUpload.value.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      const numPages = pdfDoc.getPageCount();

      if (numPages > 1) {
        isToContinue = false;

        let promises = [];

        for (let i = 0; i < numPages; i++) {
          const newPdfDoc = await PDFDocument.create();
          const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i]);
          newPdfDoc.addPage(copiedPage);

          const pdfBytes = await newPdfDoc.save();
          const blob = new Blob([pdfBytes], { type: "application/pdf" });

          let abbr = "";
          let speciality = getPlaneSpecialities.value.find(
            (p) => p.key === plan.specialityKey
          );
          switch (speciality.name) {
            case "Estructuras":
              abbr = "S";
              break;
            case "Arquitectura":
              abbr = "A";
              break;
            case "Instalaciones Eléctricas":
              abbr = "E";
              break;
            case "Instalaciones Sanitarias":
              abbr = "P";
              break;
            case "Instalaciones Mecánicas":
              abbr = "M";
              break;
          }

          promises.push(
            savePlane(
              { ...plan, name: `${plan.name} - ${abbr}${i + 1}` },
              blob,
              "pdf"
            )
          );
        }

        await Promise.all(promises);
      }
    }

    if (isToContinue) await savePlane(plan, fileToUpload.value);

    emit("onComplete");
  } catch (error) {
    responseType.value = "error";
    responseMessage.value =
      error.message || "Ocurrió un problema al guardar el plano";
  } finally {
    loadingSave.value = false;
  }
};

const savePlane = async (plan, file, extension) => {
  if (props.action === "create") {
    const planCreated = await store.dispatch("createPlan", { plan });
    plan.key = planCreated.key;
  } else {
    plan.key = props.planSelected.key;
  }

  if (file) {
    const fileUrl = `/works/${getWork.value.key}/plans/${plan.key}/${
      plan.key
    }.${extension || file.name.split(".").pop()}`;

    await uploadFileToStorage(fileUrl, file);

    plan.fileUrl = fileUrl;
  }

  await store.dispatch("updatePlan", { plan });
};

const previewFiles = (event) => {
  fileToUpload.value = event.target.files[0];
};
</script>
