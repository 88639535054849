<template>
  <Modal :open="open" :large="true" @update:open="onClose">
    <template #body>
      <!-- <img :src="plan.convertedImage" height="150" /> -->
      <iframe
        ref="viewer"
        :src="viewerUrl"
        width="100%"
        height="500px"
        allow="fullscreen"
      ></iframe>
    </template>
  </Modal>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { useStore } from "vuex";

const emit = defineEmits(["update:open"]);

const props = defineProps({
  open: {
    type: Boolean,
    default: false,
  },
  plan: {
    type: Object,
    required: true,
  },
});

const store = useStore();

const viewerUrl = ref("");

const getPlanViewerUrl = async () => {
  try {
    const accessToken = await store.dispatch("generateAutodeskAccessToken");
    viewerUrl.value = `${process.env.VUE_APP_PLAN_VIEWER_URL}?urn=${props.plan.urn}&access_token=${accessToken}`;
  } catch (error) {
    console.error(error);
  }
};

const init = async () => {
  await getPlanViewerUrl();
};

init();

const onClose = () => {
  emit("update:open", false);
};
</script>
